import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface DarkLightModeState {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    mode: "dark" | "light";
}

const initialState: DarkLightModeState = {
    primaryColor: "#ffffff",
    secondaryColor: "#1f1f1f",
    tertiaryColor: "#72c3ee",
    mode: "dark",
};

export const darkLightModeSlice = createSlice({
    name: "darkLightMode",
    initialState,
    reducers: {
        toggleMode: (state) => {
            state.mode = state.mode === "dark" ? "light" : "dark";
            state.primaryColor = state.mode === "dark" ? "#ffffff" : "#1f1f1f";
            state.secondaryColor =
                state.mode === "dark" ? "#1f1f1f" : "#ffffff";
            state.tertiaryColor = state.mode === "dark" ? "#72c3ee" : "#6247AA";
        },
    },
});

export const { toggleMode } = darkLightModeSlice.actions;

export const selectPrimaryColor = (state: {
    darkLightMode: DarkLightModeState;
}) => state.darkLightMode.primaryColor;
export const selectSecondaryColor = (state: {
    darkLightMode: DarkLightModeState;
}) => state.darkLightMode.secondaryColor;
export const selectTertiaryColor = (state: {
    darkLightMode: DarkLightModeState;
}) => state.darkLightMode.tertiaryColor;

export default darkLightModeSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import languageReducer from "./languagesSlice";
import darkLightModeReducer from "./darkLightModeSlice";
import sectionReducer from "./sectionSlice";
import projectReducer from "./projectsSlice";
import mobileReducer from "./mobileSlice";
import contactReducer from "./contactSlice";

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        language: languageReducer,
        darkLightMode: darkLightModeReducer,
        section: sectionReducer,
        project: projectReducer,
        mobile: mobileReducer,
        contact: contactReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface LanguagesState {
    value: string;
    userHovered: boolean;
}

const initialState: LanguagesState = {
    value: "",
    userHovered: false,
};

export const languagesSlice = createSlice({
    name: "languages",
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
        setHovered: (state, action: PayloadAction<boolean>) => {
            state.userHovered = action.payload;
        },
    },
});

export const { setLanguage, setHovered } = languagesSlice.actions;  

export const selectLanguage = (state: { languages: LanguagesState; }) => state.languages.value;
export const selectHovered = (state: { languages: LanguagesState; }) => state.languages.userHovered;

export default languagesSlice.reducer;
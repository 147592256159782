module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_gatsby@4.25.8_rea_ajceorey4looix5pds7v7xgcti/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.8_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.4.2_webpack-cli@5.1.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import * as React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ContactState {
    isContactHovered: boolean;
    isDownloadCVHovered: boolean;
}

const initialState: ContactState = {
    isContactHovered: false,
    isDownloadCVHovered: false,
};

export const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        setIsContactHovered: (state, action: PayloadAction<boolean>) => {
            state.isContactHovered = action.payload;
        },
        setIsDownloadCVHovered: (state, action: PayloadAction<boolean>) => {
            state.isDownloadCVHovered = action.payload;
        },
    },
});

export const { setIsContactHovered, setIsDownloadCVHovered } =
    contactSlice.actions;

export const selectIsContactHovered = (state: { contact: ContactState }) =>
    state.contact.isContactHovered;
export const selectIsDownloadCVHovered = (state: { contact: ContactState }) =>
    state.contact.isDownloadCVHovered;

export default contactSlice.reducer;

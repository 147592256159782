import * as React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MobileState {
    isMobile: boolean;
    istablet: boolean;
    isDesktop: boolean;
}

function getWindowDimensions() {
    if (typeof window !== "undefined") {
        return window.innerWidth;
    }

    return 1024;
}

const initialState: MobileState = {
    isMobile: getWindowDimensions() <= 768,
    istablet: 768 < getWindowDimensions() && getWindowDimensions() <= 1023,
    isDesktop: getWindowDimensions() > 1023,
};

export const mobileSlice = createSlice({
    name: "mobile",
    initialState,
    reducers: {
        setIsMobile: (state, action: PayloadAction<boolean>) => {
            state.isMobile = action.payload;
        },
        setIsTablet: (state, action: PayloadAction<boolean>) => {
            state.istablet = action.payload;
        },
        setIsDesktop: (state, action: PayloadAction<boolean>) => {
            state.isDesktop = action.payload;
        },
    },
});

export const { setIsMobile, setIsTablet, setIsDesktop } = mobileSlice.actions;

export const selectIsMobile = (state: { mobile: MobileState }) =>
    state.mobile.isMobile;
export const selectIsTablet = (state: { mobile: MobileState }) =>
    state.mobile.istablet;
export const selectIsDesktop = (state: { mobile: MobileState }) =>
    state.mobile.isDesktop;

export default mobileSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SectionState {
    currentSection: string;
    mobileMenuOpen: boolean;
    firstLoad: boolean;
}

const initialState: SectionState = {
    currentSection: "home",
    mobileMenuOpen: false,
    firstLoad: true,
};

export const sectionSlice = createSlice({
    name: "section",
    initialState,
    reducers: {
        setCurrentSection: (state, action: PayloadAction<string>) => {
            state.currentSection = action.payload;
        },
        setMobileMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.mobileMenuOpen = action.payload;
        },
        setFirstLoad: (state, action: PayloadAction<boolean>) => {
            state.firstLoad = action.payload;
        },
    },
});

export const { setCurrentSection, setMobileMenuOpen, setFirstLoad } =
    sectionSlice.actions;

export const selectCurrentSection = (state: { section: SectionState }) =>
    state.section.currentSection;

export const selectMobileMenuOpen = (state: { section: SectionState }) =>
    state.section.mobileMenuOpen;

export const selectFirstLoad = (state: { section: SectionState }) =>
    state.section.firstLoad;

export default sectionSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ProjectsList } from "../components/Projects/ProjectsList";

interface Project {
    title: string;
    description1: string;
    description2: string;
    link: string;
    image: string;
    mobileImage: string;
    skills: string[];
}

export interface ProjectState {
    selectedProject: string;
    mobileImage: string;
    currentList: Project[];
}

const initialState: ProjectState = {
    selectedProject: "",
    mobileImage: "",
    currentList: [],
};

export const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setSelectedProject: (state, action: PayloadAction<string>) => {
            state.selectedProject = action.payload;
        },
        setMobileIcon: (state, action: PayloadAction<string>) => {
            state.mobileImage = action.payload;
        },
        setCurrentList: (state, action: PayloadAction<Project[]>) => {
            state.currentList.splice(
                0,
                state.currentList.length,
                ...action.payload
            );
        },
    },
});

export const { setSelectedProject, setMobileIcon, setCurrentList } =
    projectsSlice.actions;

export const selectSelectedProject = (state: { projects: ProjectState }) =>
    state.projects.selectedProject;
export const selectMobileIcon = (state: { projects: ProjectState }) =>
    state.projects.mobileImage;
export const selectCurrentList = (state: { projects: ProjectState }) =>
    state.projects.currentList;

export default projectsSlice.reducer;
